import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { User } from '../../../../../auth/models/user.model';
import { LoginService } from '../../../../../auth/services/login.service';
import { Pagination, parsePagination } from '../../../../../models/pagination.model';
import { ComponentCommService } from '../../../../../services/component-comm.service';
import { ApproverDecision, DoA } from '../../../../../ui/models/doa.model';
import { DoAService } from '../../../../../ui/services/doa.service';
import { TableFilters } from '../../../../../utilities/table-filters';

@Component({
  selector: 'ag-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss']
})
export class ApprovalsComponent extends TableFilters implements OnInit, OnDestroy {

  public doas: DoA[];
  /** Whether the selection checkboxes are shown or not. */
  public enableSelection: boolean;
  public paginationData: Pagination;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public selected: DoA[] = [];
  public user: User;
  public myDecisions: { [id: number]: ApproverDecision } = {};

  private accountIds: number[];
  private pusherSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private loginService: LoginService,
    private doAService: DoAService,
    private componentComm: ComponentCommService
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'DOA.TITLE' });

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      if (user) {
        this.user = user;
        this.accountIds = this.user.accounts.map(account => account.id);
        // Filters based on URL
        this.onFiltersChange = this.loadData;
        this.setupFilters();
      }
    }));
  }

  private loadData(): void {
    this.processing = true;
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.doAService.watch(this.user.id, Object.assign(this.filters, { userId: this.user.id })).subscribe(response => {
      this.doas = response.body;
      this.paginationData = parsePagination(response.headers);
      this.checkMyDecisions();
      this.processing = false;
    });
  }

  private checkMyDecisions(): void {
    this.doas.forEach(doa => {
      this.myDecisions[doa.id] = doa.decisions.find(decision => this.accountIds.includes(decision.accountId));
    });
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    super.ngOnDestroy();
  }
}
